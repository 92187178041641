.createRoom {
  background: var(--color-primary);
  padding: 2rem;
  border-radius: 12px;
  width: 50rem;
  box-shadow: var(--shadow-light);
  text-align: center;
  display: flex;
  flex-direction: column;

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  &__input {
    flex-grow: 1;
  }

  &__input,
  &__button {
    padding: 2rem;
    margin: 0.5rem;
    font-size: 2rem;
  }
}
