.register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: var(--color-primary);

  a {
    display: none;
    font-size: 2rem;
    padding: 1rem;
    margin: 0.25rem 1.75rem 0.25rem 1.75rem;
    color: var(--color-secondary);
    text-align: center;
    text-decoration: none;

    &:hover {
      color: var(--color-secondary-light);
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    background-color: var(--color-primary-dark);
    border-radius: 12px;
    margin: 1rem;
  }

  &__form-input {
    font-size: 2.25rem;
    padding: 1.25rem;
    margin: 0.75rem 2rem;
    border-radius: 12px;
    outline: none;
    border: none;
    background-color: var(--color-grey-light-1);
    color: var(--color-grey-dark-1);

    &:first-child {
      margin-top: 2rem;
    }
  }
  &__form-button {
    cursor: pointer;
    font-size: 2.25rem;
    padding: 1.25rem;
    margin: 0.75rem 2rem 2rem 2rem;
    border-radius: 12px;
    border: none;
  }
}
