.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background-color: var(--color-primary);

  &__button {
    font-size: 2rem;
    padding: 1rem;
  }
}
